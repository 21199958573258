body {
  font-family: 'Lucida Grande' !important;
  background: rgb(250, 249, 249);
}

.MuiCard-root {
  font-family: 'Lucida Grande' !important;
  box-shadow: 0 1px 1px #029a9c !important;
  opacity: 0.9;
}

.MuiButton-root {
  font-family: 'Lucida Grande' !important;
  font-weight: bold !important;
  background-image: linear-gradient(#029a9c, #029a9c 0%, #029a9c 70%);
}

.MuiDrawer-root.MuiDrawer-modal.MuiModal-root {
  font-family: 'Lucida Grande' !important;
  z-index: 1300 !important;
}

.MuiTypography-root {
  font-family: 'Lucida Grande' !important;
  color: #023a3d;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

/* 
* {
  -ms-overflow-style: none;
} */

/* 
::-webkit-scrollbar {
  display: none;
} */

/* iframe {
  width: 100%;
  height: 700px;
  border: 0;
  overflow: hidden;
} */

.RejectBTN {
  background-image: linear-gradient(#e74c3c, #e74c3c 0%, #e74c3c 70%);
}

/* input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

.without_ampm::-webkit-datetime-edit-ampm-field {
  display: none;
}

.without_ampm::-webkit-datetime-edit-ampm-field {
  display: none;
}

input[type="time"] {
  z-index: 0;
  position: relative;
  padding: 4px 3px;
}

input[type="time"]:after {
  content: "";
  height: 20px;
  width: 20px;

  background-size: contain;
  z-index: -1;
  position: absolute;
  right: 0;
} */

.ag-theme-alpine .ag-cell {
  border: 0.5px solid #ccc;
} 

/* .ag-theme-alpine .ag-header-cell {
  border: 1px solid #ccc;
} */

/* .ag-theme-alpine .ag-row {
  border: 0.5px solid #ccc;
} */

/* .ag-theme-alpine .ag-row:first-child .ag-cell {
  border-top: 1px solid #ccc;
} */