
.ratings-container {
    display: flex;
    gap: 4px;
}

.ball {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
}

.green-ball {
    background-color: green;
}

.orange-ball {
    background-color: orange;
}

.red-ball {
    background-color: red;
}
